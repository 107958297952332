<template>
  <div>
    <h3 class="pb-2">
      Product Type Management
      <hr />
    </h3>
    <FormErrorAlert
      v-if="errors.length > 0"
      :errors="errors"
      :formRef="this.$refs.productTypeForm"
    />
    <div v-if="!loading">
      <div v-if="!productType">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Product Types</div>
          </div>
          <div class="card-body">
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="productTypes"
              stacked="md"
              small
              class="mt-4"
              bordered
              sort-icon-left
            >
              <template v-slot:cell(id)="data">
                <button
                  class="btn btn-primary btn-sm mr-2"
                  @click="viewProductType(data.item.id)"
                >
                  View
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="modifyProductType(data.item.id)"
                >
                  Modify
                </button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header">
          <div class="card-title mb-0">Product Type</div>
        </div>
        <div class="card-body">
          <ValidationObserver ref="productTypeForm">
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    name="Product Type Name"
                    id="name"
                    rules="required"
                    v-model="productType.name"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The name for the product type.</small
                  >
                </div>
                <div class="col-md-6 col-sm-12">
                  <TextInput
                    rules="required"
                    name="Product Type Icon"
                    id="icon"
                    v-model="productType.icon"
                    :disabled="viewOnly"
                  />
                  <small class="form-text text-muted mb-2"
                    >The icon for the product type.</small
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <ValidationProvider
                    name="Booking Type"
                    rules="required"
                    v-slot="{ errors, ariaInput, ariaMsg }"
                  >
                    <label
                      @click="$refs.select.focus()"
                      :class="{ error: errors[0] }"
                      for="bookingType"
                    >
                      Booking Type
                      <span class="error">*</span>
                    </label>
                    <select
                      class="form-control form-control-lg"
                      id="bookingType"
                      v-model="productType.bookingTypeId"
                      v-bind="ariaInput"
                      :disabled="viewOnly"
                    >
                      <option
                        v-for="item in bookingTypes"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.name }}</option
                      >
                    </select>
                    <ul class="mt-1 mb-0 pl-3" v-if="errors.length > 0">
                      <li
                        v-for="(error, index) in errors"
                        :key="index"
                        class="error"
                        v-bind="ariaMsg"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </ValidationProvider>
                  <small class="form-text text-muted mb-2"
                    >The booking type applicable to this product class.</small
                  >
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-lg btn-primary mt-4 mr-2"
                v-show="!viewOnly"
              >
                Save
              </button>
              <button
                class="btn btn-lg btn-primary mt-4 mr-2"
                @click="viewOnly = false"
                type="button"
                v-show="viewOnly"
              >
                Modify
              </button>
              <button
                class="btn btn-cancel mt-4 mr-2"
                @click="cancel"
                type="button"
              >
                Cancel
              </button>
              <p class="mt-2">
                <span style="color: red;">*</span> Indicates a required field
              </p>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminProductManagementService from "@/services/admin/AdminProductManagementService.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminLookupService from "@/services/admin/AdminLookupService.js";

export default {
  name: "AdminProductTypeManagement",
  title: "Admin - Product Type Management",
  components: {
    ValidationObserver,
    ValidationProvider,
    TextInput,
    FormErrorAlert
  },
  data() {
    return {
      loading: false,
      errors: [],
      productTypes: [],
      bookingTypes: [],
      fields: [
        { key: "name", sortable: true, label: "Product Type Name" },
        { key: "id", label: "Actions/Options", class: "actionsColumn" }
      ],
      productType: null,
      viewOnly: false
    };
  },
  methods: {
    getBookingTypes() {
      const lookupService = new AdminLookupService(this.tenantId);
      lookupService.getBookingTypes().then(response => {
        this.bookingTypes = response.data;
      });
    },
    async getProductTypes() {
      this.loading = true;
      const service = new AdminProductManagementService(this.tenantId);
      const response = await service.getAllProductTypes();
      if (response) {
        this.productTypes = response;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    async onSubmit() {
      this.$refs.productTypeForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.productTypeForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.productTypeForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            const service = new AdminProductManagementService(this.tenantId);
            const response = await service.updateProductType(this.productType);
            if (response?.statusCode === "Success") {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message:
                  "Product Type " +
                  this.productType.name +
                  " successfully modified.",
                layer: "admin"
              });
              this.getProductTypes();
              this.cancel();
            } else {
              this.loading = false;
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Something went wrong...",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          }
        }
      });
    },
    viewProductType(id) {
      this.viewOnly = true;
      this.productType = { ...this.productTypes.find(x => x.id === id) };
    },
    modifyProductType(id) {
      this.viewOnly = false;
      this.productType = { ...this.productTypes.find(x => x.id === id) };
    },
    cancel() {
      this.errors = [];
      this.viewOnly = false;
      this.productType = null;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  created() {
    this.getProductTypes();
    this.getBookingTypes();
  }
};
</script>

<style>
.actionsColumn {
  width: 300px !important;
}
</style>
